import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const exportReceipts = async (token, filtersData, filename) => {

    const url = `${BASE_API_URL}/stock-movements/receipt/export`;

    return axios.post(url, {searchRequests: filtersData, fileName: filename}, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response;
        }).catch(error => {
            console.log('Error on exportReceipts call: ', error);
            return error;
        });
}

export const exportStock = async (token, filtersData, filename) => {

    const url = `${BASE_API_URL}/stock/export`;

    return axios.post(url, {searchRequests: filtersData, fileName: filename}, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response;
        }).catch(error => {
            console.log('Error on exportStock call: ', error);
            return error;
        });
}

export const exportIssues = async (token, filtersData, filename) => {

    const url = `${BASE_API_URL}/stock-movements/issue/export`;

    return axios.post(url, {searchRequests: filtersData, fileName: filename}, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response;
        }).catch(error => {
            console.log('Error on exportIssues call: ', error);
            return error;
        });
}
