import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getVehicle, editVehicle } from "../services/VehiclesService"
import { searchProvidersByName } from "../services/ProvidersService";
import { useAppContext } from "../contextApi/context"
import { VEHICLE_PLATE_DUPLICATED } from "../constants/apiMessages"
import { vehicleType } from "../utils/translation";

const EditVehicle = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    let [vehicleTypes] = useState(['CARGO', 'LIGHT', 'YELLOW']);

    const [timer, setTimer] = useState(null);
    let [availableProviders, setAvailableProviders] = useState([]);
    let [selectedProvider, setSelectedProvider] = useState(null);
    let [showProviderError, setShowProviderError] = useState(null);


    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [showVehicleEdited, setShowVehicleEdited] = useState(false);
    const [showErrorOnEdit, setShowErrorOnEdit] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('');

    // ----- API Fetch Actions
    const fetchVehicleData = useCallback(async () => {
        return await getVehicle(loggedUser.token, id);
    }, [loggedUser.token, id]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            plate: '',
            vin: '',
            make: '',
            model: '',
            year: '',
            color: '',
            details: '',
            odometer: '',
            type: ''
        }
    });

    useEffect(() => {
        fetchVehicleData().then(data => {
            setCurrentVehicle(data);
            setSelectedProvider({ id: data.providerId, label: data.providerName });
            reset(data);
        });
    }, [fetchVehicleData, reset]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        if (selectedProvider !== null) {
            data = {...data, providerId: selectedProvider.id};
            const response = await editVehicle(loggedUser.token, currentVehicle.id, data);

            if (response.status === 200) {
                setShowVehicleEdited(true);
            } else {
                // Error from API
                const { data } = response;
                let errorMessageDuplicated = "El vehículo contiene datos que ya se encuentran registrados:";

                if (data.includes(VEHICLE_PLATE_DUPLICATED)) {
                    errorMessageDuplicated += " Número de placa,"
                }

                setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
                setShowErrorDuplicatedData(true);
            }
        } else {
            setShowProviderError(true);
        }
    }

    const handleFormErrors = () => {
        if (selectedProvider === null) {
            setShowProviderError(true);
        }
    }

    const handleSearchProviders = (e, newValue, reason) => {
        clearTimeout(timer);
        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableProviders([]);
                setSelectedProvider(null);
            }
            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchProvidersByName(loggedUser.token, newValue)
                    setAvailableProviders(response?.map(provider => ({ id: provider.id, label: provider.name })));
                }
            }
            else {
                setAvailableProviders([]);
                setSelectedProvider(null);
            }
        }, 500);
        setTimer(newTimer)
    }

    const handleSelectProvider = async (event, newValue) => {
        if (newValue != null) {
            setShowProviderError(false);
            setSelectedProvider(newValue);
        } else {
            setSelectedProvider(null);
        }
    }

    const clearMessages = () => {
        setShowVehicleEdited(false);
        setShowErrorOnEdit(false);
        setShowErrorDuplicatedData(false);
        setShowProviderError(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            {showVehicleEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Vehículo editado satisfactoriamente.
                </div>
            }

            {showErrorOnEdit &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    Vehículo no editado.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <div className="mb-3">
                <Link to="/vehiculos">Volver a página de vehículos</Link>
            </div>

            <h1>Editar Vehículo</h1>
            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit, handleFormErrors)}>
                    <label className="d-flex mb-1" htmlFor="plate">Número de placa</label>
                    <input className="form-control" type="text" id="plate" autoComplete="off" maxLength={20} {...register("plate", { required: true, maxLength: 20 })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.plate?.type === "required" && `Campo requerido`}
                        {errors.plate?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="vin">VIN</label>
                    <input className="form-control" type="text" id="vin" autoComplete="off" maxLength={17} {...register("vin", { required: true, pattern: /^[a-zA-Z0-9]{17}/, maxLength: 17 })} />
                    <span className="my-1" role="alert">
                        {errors.vin?.type === "required" && `Campo requerido`}
                        {errors.vin?.type === "maxLength" && `Formato inválido`}
                        {errors.vin?.type === "pattern" && `Debe tener 17 caracteres (Solo números y letras)`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="make">Marca</label>
                    <input className="form-control" type="text" id="make" autoComplete="off" maxLength={25} {...register("make", { required: true, maxLength: 25 })} />
                    <span className="my-1" role="alert">
                        {errors.make?.type === "required" && `Campo requerido`}
                        {errors.make?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="model">Modelo</label>
                    <input className="form-control" type="text" id="model" autoComplete="off" maxLength={25} {...register("model", { required: true, maxLength: 25 })} />
                    <span className="my-1" role="alert">
                        {errors.model?.type === "required" && `Campo requerido`}
                        {errors.model?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="year">Año</label>
                    <input className="form-control" type="text" id="year" autoComplete="off" maxLength={4} {...register("year", { required: true, pattern: /^\d*\.?\d*$/, maxLength: 4 })} />
                    <span className="my-1" role="alert">
                        {errors.year?.type === "required" && `Campo requerido`}
                        {errors.year?.type === "maxLength" && `Formato inválido`}
                        {errors.year?.type === "pattern" && `Sólo se permiten números`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="type">Tipo</label>
                    <select className="form-select" {...register("type", { required: true })}>
                        <option value="">Seleccione un tipo</option>
                        {vehicleTypes?.map((type, index) =>
                            (<option key={index} value={type}>{vehicleType(type)}</option>)
                        )}
                    </select>
                    <span className="my-1" role="alert">
                        {errors.type && `Campo requerido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="color">Color</label>
                    <input className="form-control" type="text" id="color" autoComplete="off" maxLength={25} {...register("color", { required: true, maxLength: 25 })} />
                    <span className="my-1" role="alert">
                        {errors.color?.type === "required" && `Campo requerido`}
                        {errors.color?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="odometer">Kilometraje</label>
                    <input className="form-control" type="text" id="odometer" autoComplete="off" maxLength={10} {...register("odometer", { pattern: /^\d*\.?\d*$/, maxLength: 10 })} />
                    <span className="my-1" role="alert">
                        {errors.odometer?.type === "maxLength" && `Formato inválido`}
                        {errors.odometer?.type === "pattern" && `Sólo se permiten números`}
                    </span>

                    <label className="d-flex mb-1">Proveedor</label>
                    <Autocomplete
                        disablePortal
                        id="size-small-outlined"
                        size="small"
                        options={availableProviders}
                        renderInput={(params) => <TextField {...params} label="" />}
                        onChange={handleSelectProvider}
                        onInputChange={handleSearchProviders}
                        noOptionsText={"Buscar por nombre"}
                        value={selectedProvider}
                    />
                    <span className="my-1" role="alert">
                        {showProviderError && `Campo requerido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="details">Detalles</label>
                    <textarea className="form-control" id="details" maxLength={100} rows="3" {...register("details", { maxLength: 100 })} />
                    <span className="my-1" role="alert">
                        {errors.details?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        > button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default EditVehicle;