import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"

import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MdDelete, MdModeEdit } from "react-icons/md";
import moment from 'moment';

import { useAppContext } from "../contextApi/context";
import { searchArticleInStock } from "../services/StockService";
import { searchUsersByName } from "../services/UsersService";
import { searchVehiclesByPlate } from "../services/VehiclesService";
import { createMovement } from "../services/StockMovementService";
import { TableActionButton } from "../styles/StyledComponents";
import CustomDatePicker from "../components/shared/CustomDatePicker";
import IssueMovementConfirmationModal from "../components/shared/IssueMovementConfirmationModal";
import IssueEditDetailModal from "../components/shared/IssueEditDetailModal";
import { TWO_DECIMALS_GREATER_THAN_ZERO, ONLY_NUMBERS_GREATER_THAN_ZERO } from "../constants/regex";

const CreateIssueMovement = () => {

    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            odometer: 0
        }
    });
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset: reset2 } = useForm({
        defaultValues: {
            amount: ''
        }
    });

    // ----- State
    const [timer, setTimer] = useState(null);
    let [details, setDetails] = useState([]);
    let [description, setDescription] = useState(null);
    let [newOdometerValue, setNewOdometerValue] = useState(null);
    let [issueDate, setIssueDate] = useState(moment.utc(Date.now()).format());
    const [articleNow, setArticleNow] = useState(Date.now());

    let [foundArticlesInStock, setFoundArticlesInStock] = useState([]);
    let [availableArticles, setAvailableArticles] = useState([]);
    let [selectedArticle, setSelectedArticle] = useState(null);
    let [showArticleError, setShowArticleError] = useState(null);
    let [showAmountError, setShowAmountError] = useState(false);

    let [availableVehicles, setAvailableVehicles] = useState([]);
    let [selectedVehicleId, setSelectedVehicleId] = useState(null);
    let [selectedVehicle, setSelectedVehicle] = useState(null);
    let [showVehicleError, setShowVehicleError] = useState(null);
    let [showErrorOdometer, setShowErrorOdometer] = useState(false);

    let [availableUsers, setAvailableUsers] = useState([]);
    let [selectedUserId, setSelectedUserId] = useState(null);
    let [selectedUserName, setSelectedUserName] = useState(null);
    let [showUserError, setShowUserError] = useState(false);

    let [showConfirmation, setShowConfirmation] = useState(false);
    let [payload, setPayload] = useState(null);
    let [regexAmount, setRegexAmount] = useState(TWO_DECIMALS_GREATER_THAN_ZERO);

    let [showEditDetailModal, setShowEditDetailModal] = useState(false);
    let [detailToEdit, setDetailToEdit] = useState(null);
    let [detailIdToEdit, setDetailIdToEdit] = useState(null);

    // ----- Actions
    const handleAddDetail = async (data) => {
        if (selectedArticle === null) {
            setShowArticleError(true);
        } else if (selectedArticle.amount < Number(data.amount)) {
            setShowAmountError(true);
        } else {
            const newDetail = {
                articleId: selectedArticle.articleId,
                articleName: selectedArticle.articleName,
                brand: selectedArticle.brand,
                amount: Number(data.amount),
                itemsValue: selectedArticle.averageItemsValue,
                measureUnit: selectedArticle.measureUnit,
                currentAmount: selectedArticle.amount
            };
            details.push(newDetail);
            setDetails(details);
            clearAfterAddDetail();
            reset2();
        }
    }

    const handleSaveMovement = async () => {
        const response = await createMovement(loggedUser.token, payload);

        if (response.status === 201) {
            setShowConfirmation(false);
            navigate("/despacho?created=true");
        } else {
            // Error from API
        }
    }

    const handleShowConfirmation = (data) => {
        if (isAllDataFilled(data.odometer)) {
            const payload = {
                type: "ISSUE",
                destinationId: selectedVehicleId,
                receivingUserId: selectedUserId,
                odometer: data.odometer,
                description: data.description,
                eventDate: issueDate,
                details: details.map(item => {
                    return {
                        articleId: item.articleId,
                        amount: item.amount,
                        brand: item.brand,
                    }
                }),
            };

            setPayload(payload);
            setShowConfirmation(true);
        }
    }

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    }

    const handleFormErrors = () => {
        isAllDataFilled();
    }

    const handleForm2Errors = () => {
        if (selectedArticle === null) {
            setShowArticleError(true);
        }
    }

    const handleSelectArticle = async (event, newValue) => {
        if (newValue != null) {
            setShowArticleError(false);
            let articleFound = foundArticlesInStock.find(article => article.id === newValue.id);

            if (articleFound.measureUnit === "UNITS") {
                setRegexAmount(ONLY_NUMBERS_GREATER_THAN_ZERO)
            }
            else {
                setRegexAmount(TWO_DECIMALS_GREATER_THAN_ZERO);
            }

            setSelectedArticle(articleFound);

        } else {
            setSelectedArticle(null);
        }
    }

    const handleSearchArticles = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                reset2({ amount: '' });
                setAvailableArticles([]);
                setSelectedArticle(null);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    let response = await searchArticleInStock(loggedUser.token, newValue);
                    response = response.map((item, index) => {
                        return {
                            ...item,
                            id: index
                        }
                    })

                    const articleIdsSelected = details.map(detail => detail.articleId);
                    const articleBrandsSelected = details.map(detail => detail.brand);

                    response = response.filter((article) => {
                        return !(articleIdsSelected.includes(article.articleId) && articleBrandsSelected.includes(article.brand));
                    });

                    setFoundArticlesInStock(response);
                    setAvailableArticles(response?.map(article => ({ id: article.id, label: `${article.internalCode} - ${article.articleName} (${article.brand})` })));
                }
            }
            else {
                setAvailableArticles([]);
                setSelectedArticle(null);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectVehicle = async (event, newValue) => {
        if (newValue != null) {
            setShowVehicleError(false);
            setSelectedVehicleId(newValue.id);
            const found = availableVehicles.find(vehicle => vehicle.id === newValue.id);
            setSelectedVehicle(found);
            reset({ odometer: found.odometer ? found.odometer : '0' });
            setNewOdometerValue(found.odometer ? found.odometer : '0');
        } else {
            setSelectedVehicleId("");
        }
    }

    const handleSearchVehicles = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableVehicles([]);
                setSelectedVehicleId(null);
                setSelectedVehicle(null);
                reset({ odometer: 0 });
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchVehiclesByPlate(loggedUser.token, newValue)
                    setAvailableVehicles(response?.map(vehicle => ({ id: vehicle.id, label: vehicle.plate, odometer: vehicle.odometer })));
                }
            }
            else {
                setAvailableVehicles([]);
                setSelectedVehicleId(null);
                setSelectedVehicle(null);
                reset({ odometer: 0 });
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectUser = async (event, newValue) => {
        if (newValue != null) {
            setShowUserError(false);
            setSelectedUserId(newValue.id);
            setSelectedUserName(newValue.label)
        } else {
            setSelectedUserId("");
        }
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleOdometerChange = (e) => {
        setNewOdometerValue(e.target.value);
    }

    const handleAmountChange = (e) => {
        setShowAmountError(false);
        if (selectedArticle.amount < Number(e.target.value)) {
            setShowAmountError(true);
        }
    }

    const handleSearchUsers = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableUsers([]);
                setSelectedUserId(null);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchUsersByName(loggedUser.token, newValue)
                    setAvailableUsers(response?.map(user => ({ id: user.id, label: `${user.firstName} ${user.lastName} (${user.username})` })));
                }
            }
            else {
                setAvailableUsers([]);
                setSelectedUserId(null);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleDelete = (id) => {
        let deleted = details[id];
        details = details.filter(function (detail) {
            return detail !== deleted
        });
        setDetails(details);
    }

    const handleOpenDetail = (index) => {
        let detail = details[index];
        setDetailIdToEdit(index);
        setDetailToEdit(detail);
        setShowEditDetailModal(true);
    }

    const handleCloseDetail = () => {
        setDetailToEdit(null);
        setDetailIdToEdit(null);
        setShowEditDetailModal(false);
    }

    const handleEditDetail = (detail) => {
        details[detailIdToEdit] = detail;
        setShowEditDetailModal(false);
    }

    const isAllDataFilled = (newOdometer) => {
        clearMessages();

        let allGood = true;

        if (selectedUserId === null) {
            allGood = false;
            setShowUserError(true);
        }

        if (selectedVehicleId === null) {
            allGood = false;
            setShowVehicleError(true);
        }

        if (newOdometer < selectedVehicle?.odometer) {
            allGood = false;
            setShowErrorOdometer(true);
        }

        return allGood;
    }

    const clearMessages = () => {
        setShowArticleError(false);
        setShowVehicleError(false);
        setShowUserError(false);
        setShowErrorOdometer(false);
    }

    const clearAfterAddDetail = () => {
        setShowArticleError(false);
        setShowAmountError(false);

        // Clear Articles autocomplete
        setAvailableArticles([]);
        setSelectedArticle(null);
        setArticleNow(Date.now());
    }

    // ----- Render
    return (
        <div className="container mt-4">

            <div className="mb-3">
                <Link to="/despacho">Volver a página de despacho</Link>
            </div>

            <h1 className="mb-5">Crear despacho</h1>

            <ContainerStyled>
                <h3 className="mt-4">Datos generales</h3>
                <form onSubmit={handleSubmit(handleShowConfirmation, handleFormErrors)}>
                    <label className="d-flex mb-2">Fecha de despacho</label>
                    <CustomDatePicker defaultDate={moment(Date.now())} minDate={moment(Date.now()).subtract(5, 'months')} maxDate={moment(Date.now())} actionHandler={(date) => setIssueDate(date)} />
                    <span className="my-1" role="alert" />

                    <label className="d-flex mb-1">Solicitante</label>
                    <Autocomplete
                        disablePortal
                        id="size-small-outlined"
                        size="small"
                        options={availableUsers}
                        renderInput={(params) => <TextField {...params} label="Buscar" />}
                        onChange={handleSelectUser}
                        onInputChange={handleSearchUsers}
                        noOptionsText={"Buscar por nombre, apellido o usuario"}
                        className="mt-1"
                    />
                    <span className="my-1" role="alert">
                        {showUserError && `Campo requerido`}
                    </span>

                    <label className="d-flex mb-1">Vehículo</label>
                    <Autocomplete
                        disablePortal
                        id="size-small-outlined"
                        size="small"
                        options={availableVehicles}
                        renderInput={(params) => <TextField {...params} label="Buscar" />}
                        onChange={handleSelectVehicle}
                        onInputChange={handleSearchVehicles}
                        noOptionsText={"Buscar por placa"}
                        className="mt-1"
                    />
                    <span className={`my-1 ${selectedVehicle && 'info'}`} role="alert">
                        {showVehicleError && `Campo requerido`}
                        {selectedVehicle && `Kilometraje actual: ${selectedVehicle.odometer ? selectedVehicle.odometer : '0'} km.`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="odometer">Kilometraje</label>
                    <input className="form-control" type="text" id="odometer" autoComplete="off" maxLength={10}
                        {...register("odometer", { required: true, onChange: handleOdometerChange, maxLength: 10, pattern: /^\d*\.?\d*$/ })} />
                    <span className="my-1" role="alert">
                        {errors.odometer?.type === "required" && `Campo requerido`}
                        {errors.odometer?.type === "maxLength" && `Formato inválido`}
                        {errors.odometer?.type === "pattern" && `Sólo se permiten números`}
                        {showErrorOdometer && `Debe ser igual o mayor al kilometraje actual`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" maxLength={100} rows="3"
                        {...register("description", { maxLength: 100, onChange: handleDescriptionChange })} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>

                    {details.length > 0 &&
                        <button className="btn btn-danger mt-3 save-issue" type="submit">Guardar</button>
                    }
                </form>
            </ContainerStyled>

            <ContainerStyled>
                <hr className="mt-3" />

                <h3 className="mt-4">Detalles de movimiento</h3>

                <form onSubmit={handleSubmit2(handleAddDetail, handleForm2Errors)}>
                    <label className="d-flex mb-1" htmlFor="article">Artículo</label>
                    <Autocomplete
                        key={articleNow}
                        disablePortal
                        id="size-small-outlined"
                        size="small"
                        options={availableArticles}
                        renderInput={(params) => <TextField {...params} label="Buscar" />}
                        onChange={handleSelectArticle}
                        onInputChange={handleSearchArticles}
                        noOptionsText={"Buscar por nombre o código"}
                        className="mt-1"
                    />
                    <span className={`my-1 ${selectedArticle && 'info'}`} role="alert">
                        {showArticleError && `Campo requerido`}
                        {selectedArticle && `Cantidad actual: ${selectedArticle.amount}`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="amount">Cantidad</label>
                    <input className="form-control" type="text" id="amount" autoComplete="off" maxLength={10} {...register2("amount", { required: true, maxLength: 10, pattern: regexAmount, onChange: handleAmountChange })} />
                    <span className="my-1" role="alert">
                        {errors2.amount?.type === "required" && `Campo requerido`}
                        {errors2.amount?.type === "maxLength" && `Formato inválido`}
                        {errors2.amount?.type === "pattern" && selectedArticle?.measureUnit !== "UNITS" && `Sólo se permiten números (5 - 3.50)`}
                        {errors2.amount?.type === "pattern" && selectedArticle?.measureUnit === "UNITS" && `Sólo se permiten números enteros (5 - 100)`}
                        {showAmountError && `Debe ser menor o igual a la cantidad actual`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Agregar detalle</button>
                </form>

                {details.length > 0 &&
                    <>
                        <div className="d-flex justify-content-center">
                            <table className="table table-bordered mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">Artículo</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details.map((detail, index) =>
                                    (
                                        <tr key={index}>
                                            <td>{detail.articleName}</td>
                                            <td>{detail.brand}</td>
                                            <td>{detail.amount}</td>
                                            <td className="d-flex">
                                                <TableActionButton className="btn btn-primary" onClick={() => handleOpenDetail(index)}>
                                                    <MdModeEdit />
                                                    Editar
                                                </TableActionButton>
                                                <TableActionButton className="btn btn-danger" onClick={() => handleDelete(index)}>
                                                    <MdDelete />
                                                    Eliminar
                                                </TableActionButton>
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </>
                }
            </ContainerStyled >

            {showConfirmation &&
                <IssueMovementConfirmationModal
                    date={issueDate}
                    requestor={selectedUserName}
                    vehicle={selectedVehicle.label}
                    odometer={newOdometerValue}
                    description={description?.length > 0 ? description : "--"}
                    details={details}
                    closeHandler={handleCloseConfirmation}
                    actionHandler={handleSaveMovement}
                    show={showConfirmation}
                />
            }

            {showEditDetailModal &&
                <IssueEditDetailModal
                    detail={detailToEdit}
                    closeHandler={handleCloseDetail}
                    actionHandler={handleEditDetail}
                    show={showEditDetailModal}
                />
            }

        </div>
    );
}

// ----- Styles
const ContainerStyled = styled.div`
    margin-bottom: 50px;
    
    form {
        border: #dee2e6 solid 1px;
        display: grid;
        margin: 30px auto;
        padding: 30px;
        width: 50%;
        
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;

            &.info {
                color: green;
                font-weight: 500;
            }
        }

        .save-issue {
            bottom: 35px;
            padding-left: 40px;
            padding-right: 40px;
            position: fixed;
            right: 50px;
            z-index: 1;
        }

        .MuiAutocomplete-root {
            width: 100%;
        }
    }

    button.btn-danger {
        background-color: #D04437;
    }
`;

export default CreateIssueMovement;
