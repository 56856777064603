import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getRole, editRole, editRolePermissions } from "../services/RolesService"
import { getPermissions } from "../services/PermissionsService"
import { useAppContext } from "../contextApi/context"
import { ROLE_NAME_DUPLICATED } from "../constants/apiMessages"

const EditRole = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    const [allPermissions, setAllPermissions] = useState([]);
    const [currentRole, setCurrentRole] = useState(null);
    let [newPermissions, setNewPermissions] = useState([]);
    const [showRoleEdited, setShowRoleEdited] = useState(false);
    const [showPermissonesSaved, setShowPermissonesSaved] = useState(false);
    const [showErrorOnEdit, setShowErrorOnEdit] = useState(false);
    const [showErrorDuplicatedRole, setShowErrorDuplicatedRole] = useState(false);


    // ----- API Fetch Actions
    const fetchRoleData = useCallback(async () => {
        return await getRole(loggedUser.token, id);
    }, [loggedUser.token, id]);

    const fetchPermissionsData = useCallback(async () => {
        return await getPermissions(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            description: '',
        }
    });

    useEffect(() => {
        fetchRoleData().then(data => {
            setCurrentRole(data);
            setNewPermissions(data.permissions.map(role => role.id));
            const { name, description } = data;
            reset({ name, description });
        });

        fetchPermissionsData().then(data => {
            const groupedPermissions = Object.groupBy(data, ({ entity }) => entity);
            const sortedPermissions =
                Object.keys(groupedPermissions)
                    .sort()
                    .reduce((acc, key) => ({
                        ...acc, [key]: groupedPermissions[key]
                    }), {})

            setAllPermissions(sortedPermissions);
        });

    }, [fetchPermissionsData, fetchRoleData, reset]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        const response = await editRole(loggedUser.token, currentRole.id, data);

        if (response.status === 200) {
            setShowRoleEdited(true);
        } else {
            // Error from API
            const { data } = response;

            if (data.includes(ROLE_NAME_DUPLICATED)) {
                setShowErrorDuplicatedRole(true);
            }
        }
    }

    const handleChange = (e) => {
        let permissionId = e.target.value;

        if (e.target.checked) {
            newPermissions.push(Number(permissionId));
        }
        else {
            let newList = newPermissions.filter(function (permission) {
                return permission !== Number(permissionId)
            });
            setNewPermissions(newList);
        }
    }

    const isChecked = (permissionId) => {
        if (currentRole) {
            let roleIdsSelected = currentRole.permissions.map(role => role.id);
            return roleIdsSelected.includes(permissionId);
        }
    }

    const handleSavePermissions = async () => {
        clearMessages();
        const response = await editRolePermissions(loggedUser.token, currentRole.id, newPermissions);

        if (response) {
            setShowPermissonesSaved(true);
        } else {
            // Error from API
            setShowErrorOnEdit(true);
        }
    }

    const clearMessages = () => {
        setShowRoleEdited(false);
        setShowPermissonesSaved(false);
        setShowErrorOnEdit(false);
        setShowErrorDuplicatedRole(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            {showRoleEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Rol editado satisfactoriamente.
                </div>
            }

            {showErrorOnEdit &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    Rol no editado.
                </div>
            }

            {showErrorDuplicatedRole &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    El nombre del rol ya se encuentra registrado.
                </div>
            }

            {showPermissonesSaved &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Permisos agregados al rol satisfactoriamente.
                </div>
            }

            <div className="mb-3">
                <Link to="/roles">Volver a página de roles</Link>
            </div>

            <h1>Editar Rol</h1>
            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" rows="3" maxLength={100} {...register("description", { maxLength: 100 })} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>

            <hr />

            <h1>Agregar permisos</h1>
            <PermissionsContainer>
                {
                    Object.keys(allPermissions).map(entity => {
                        if (entity === "Ordenes de Producción") {
                            return null;
                        }
                        return (
                            <div key={entity} >
                                <h5>{entity}</h5>
                                {
                                    allPermissions[entity].map(permission => {
                                        return (
                                            <div key={permission.id} className="form-check">
                                                <input className="form-check-input" defaultChecked={isChecked(permission.id)} type="checkbox" value={permission.id} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {permission.name}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                <div className="d-flex align-items-center">
                    <button className="btn btn-danger mt-5" onClick={handleSavePermissions}>Guardar permisos</button>
                </div>
            </PermissionsContainer>

        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

const PermissionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 50px 50px;

    > div {
        margin: 8px 0;
        width: 50%;

        > div {
            margin-left: 10px;
        }
    }

    > button {
        background-color: #D04437;    
    }
`;

export default EditRole;