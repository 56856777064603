import { useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { createVehicle, deleteVehicle, getVehicles } from "../services/VehiclesService"
import { searchProvidersByName } from "../services/ProvidersService";
import { useAppContext } from "../contextApi/context"
import { VEHICLE_PLATE_DUPLICATED } from "../constants/apiMessages"
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import ConfirmationModal from "../components/shared/ConfirmationModal"
import TableContainer from "../components/shared/TableContainer";
import { AddButton, TableActionButton } from "../styles/StyledComponents"
import { checkPermission } from "../utils/permissions";
import { vehicleType } from "../utils/translation";

const Vehicles = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    let [vehicleTypes] = useState(['CARGO', 'LIGHT', 'YELLOW'])

    const [timer, setTimer] = useState(null);
    let [availableProviders, setAvailableProviders] = useState([]);
    let [selectedProviderId, setSelectedProviderId] = useState(null);
    let [showProviderError, setShowProviderError] = useState(null);

    const [idToDelete, setIdToDelete] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [showVehicleCreated, setShowVehicleCreated] = useState(false)
    const [showVehicleDeleted, setShowVehicleDeleted] = useState(false)
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // ----- Actions
    const handleCloseDeleteConfirmationModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (vehicleId) => {
        setIdToDelete(vehicleId);
        setShowDeleteConfirmation(true)
    }

    const handleCreate = async (data) => {
        clearMessages();
        if (selectedProviderId !== null) {
            data = {...data, providerId: selectedProviderId};
            const response = await createVehicle(loggedUser.token, data);

            if (response.status === 201) {
                setShowVehicleCreated(true);
                reset();
                setShowForm(false);
                setUpdateTable(!updateTable);
            } else {
                // Error from API
                const { data } = response;
                let errorMessageDuplicated = "El vehículo contiene datos que ya se encuentran registrados:";

                if (data.includes(VEHICLE_PLATE_DUPLICATED)) {
                    errorMessageDuplicated += " Número de placa,"
                }

                setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
                setShowErrorDuplicatedData(true);
            }
        } else {
            setShowProviderError(true);
        }
    }

    const handleShowForm = () => {
        setShowForm(!showForm);
    }

    const handleFormErrors = () => {
        if (selectedProviderId === null) {
            setShowProviderError(true);
        }
    }

    const handleSearchProviders = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableProviders([]);
                setSelectedProviderId(null);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchProvidersByName(loggedUser.token, newValue)
                    setAvailableProviders(response?.map(provider => ({ id: provider.id, label: provider.name })));
                }
            }
            else {
                setAvailableProviders([]);
                setSelectedProviderId(null);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectProvider = async (event, newValue) => {
        if (newValue != null) {
            setShowProviderError(false);
            setSelectedProviderId(newValue.id);
        } else {
            setSelectedProviderId(null);
        }
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteVehicle(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowVehicleDeleted(true);
        } else {
            // Error from API
            setShowDeleteConfirmation(false);
        }
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleResetForm = () => {
        reset();
        setShowForm(false);
        setShowConfirmationModal(false);
        setSelectedProviderId(null);
    }

    const clearMessages = () => {
        setShowVehicleCreated(false);
        setShowErrorDuplicatedData(false);
        setShowVehicleDeleted(false);
        setErrorDuplicatedData('');
        setShowProviderError(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_vehicles') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/vehiculos/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_vehicles') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Placa',
            selector: row => row.plate,
            sortable: true,
            sortField: 'plate',
        },
        {
            name: 'VIN',
            selector: row => row.vin,
            sortable: true,
            sortField: 'vin',
        },
        {
            name: 'Proveedor',
            selector: row => row.providerName,
        },
        {
            name: 'Marca',
            selector: row => row.make,
            sortable: true,
            sortField: 'make',
        },
        {
            name: 'Modelo',
            selector: row => row.model,
        },
        {
            name: 'Año',
            selector: row => row.year,
        },
        {
            name: 'Kilometraje',
            selector: row => row.odometer,
        },
        {
            name: 'Acciones',
            width: "210px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'plate',
            input: 'text',
            label: 'Placa',
            operation: 'like',
            length: 20
        },
        {
            column: 'vin',
            input: 'text',
            label: 'VIN',
            operation: 'like',
            length: 17
        },
        {
            column: 'make',
            input: 'text',
            label: 'Marca',
            operation: 'like',
            length: 25
        },
        {
            column: 'model',
            input: 'text',
            label: 'Modelo',
            operation: 'like',
            length: 25
        }
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            {showVehicleCreated &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Vehículo creado satisfactoriamente.
                </div>
            }

            {showVehicleDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Vehículo eliminado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <h1>Vehículos</h1>

            <div className="d-flex mt-3 justify-content-end">
                {checkPermission(loggedUser.permissions, 'add_vehicles') &&
                    <AddButton className="btn btn-primary" onClick={handleShowForm}>
                        <FaPlus /> Agregar nuevo
                    </AddButton>
                }
            </div>

            {showForm &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate, handleFormErrors)}>
                        <label className="d-flex mb-1" htmlFor="plate">Número de placa</label>
                        <input className="form-control" type="text" id="plate" autoComplete="off" maxLength={20} {...register("plate", { required: true, maxLength: 20 })} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.plate?.type === "required" && `Campo requerido`}
                            {errors.plate?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="make">Marca</label>
                        <input className="form-control" type="text" id="make" autoComplete="off" maxLength={25} {...register("make", { required: true, maxLength: 25 })} />
                        <span className="my-1" role="alert">
                            {errors.make?.type === "required" && `Campo requerido`}
                            {errors.make?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="vin">VIN</label>
                        <input className="form-control" type="text" id="vin" autoComplete="off" maxLength={17} {...register("vin", { required: true, pattern: /^[a-zA-Z0-9]{17}/, maxLength: 17 })} />
                        <span className="my-1" role="alert">
                            {errors.vin?.type === "required" && `Campo requerido`}
                            {errors.vin?.type === "maxLength" && `Formato inválido`}
                            {errors.vin?.type === "pattern" && `Debe tener 17 caracteres (Solo números y letras)`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="model">Modelo</label>
                        <input className="form-control" type="text" id="model" autoComplete="off" maxLength={25} {...register("model", { required: true, maxLength: 25 })} />
                        <span className="my-1" role="alert">
                            {errors.model?.type === "required" && `Campo requerido`}
                            {errors.model?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="year">Año</label>
                        <input className="form-control" type="text" id="year" autoComplete="off" maxLength={4} {...register("year", { required: true, pattern: /^\d*\.?\d*$/, maxLength: 4 })} />
                        <span className="my-1" role="alert">
                            {errors.year?.type === "required" && `Campo requerido`}
                            {errors.year?.type === "maxLength" && `Formato inválido`}
                            {errors.year?.type === "pattern" && `Sólo se permiten números`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="type">Tipo</label>
                        <select className="form-select" {...register("type", { required: true })}>
                            <option value="">Seleccione un tipo</option>
                            {vehicleTypes?.map((type, index) =>
                                (<option key={index} value={type}>{vehicleType(type)}</option>)
                            )}
                        </select>
                        <span className="my-1" role="alert">
                            {errors.type && `Campo requerido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="color">Color</label>
                        <input className="form-control" type="text" id="color" autoComplete="off" maxLength={25} {...register("color", { required: true, maxLength: 25 })} />
                        <span className="my-1" role="alert">
                            {errors.color?.type === "required" && `Campo requerido`}
                            {errors.color?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="odometer">Kilometraje</label>
                        <input className="form-control" type="text" id="odometer" autoComplete="off" maxLength={10} {...register("odometer", { pattern: /^\d*\.?\d*$/, maxLength: 10 })} />
                        <span className="my-1" role="alert">
                            {errors.odometer?.type === "maxLength" && `Formato inválido`}
                            {errors.odometer?.type === "pattern" && `Sólo se permiten números`}
                        </span>

                        <label className="d-flex mb-1">Proveedor</label>
                        <Autocomplete
                            disablePortal
                            id="size-small-outlined"
                            size="small"
                            options={availableProviders}
                            renderInput={(params) => <TextField {...params} label="Buscar" />}
                            onChange={handleSelectProvider}
                            onInputChange={handleSearchProviders}
                            noOptionsText={"Buscar por nombre"}
                        />
                        <span className="my-1" role="alert">
                            {showProviderError && `Campo requerido`}
                        </span>


                        <label className="d-flex mb-1" htmlFor="details">Detalles</label>
                        <textarea className="form-control" id="details" maxLength={100} rows="3" {...register("details", { maxLength: 100 })} />
                        <span className="my-1" role="alert">
                            {errors.details?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary reset" type="button" onClick={handleShowConfirmationModal}>Cancelar</button>
                            <button className="btn btn-danger" type="submit">Guardar</button>
                        </div>

                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getVehicles}
                updateTable={updateTable}
                filters={filters}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el vehículo?"}
                    closeHandler={handleCloseDeleteConfirmationModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showConfirmationModal &&
                <ConfirmationModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleResetForm}
                    show={showConfirmationModal}
                />
            }
        </div>
    )
}

const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 60%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        div > button {
            &.reset {
                background-color: #A5A7A0;
                border-color: #A5A7A0;
                margin-right: 20px;
                
                &:active {
                    background-color: #A5A7A0;
                    border-color: #A5A7A0;
                }
            }
        }

        > textarea {
            resize: none;
        }
    }
`;

export default Vehicles;