import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";
import { useForm } from "react-hook-form";

import { ONLY_LETTERS_NUMBERS_DASH_AND_UNDERSCORE } from '../../constants/regex';

const ExportAddFileNameModal = ({ show, closeHandler, exportHandler }) => {

    // ----- Context/Hooks
    const { register, handleSubmit, formState: { errors } } = useForm();

    // ----- Actions
    const handleAction = (data) => {
        exportHandler(data.filename.trim())
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={closeHandler}>
            <Modal.Header className='fw-bold' closeButton>
                Nombre de archivo
            </Modal.Header>
            <Modal.Body>
                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <label className="d-flex" htmlFor="amount">Nombre:</label>
                    <span className="d-flex note">* Este campo es opcional</span>
                    <input className="form-control" type="text" autoComplete="off" maxLength={20} {...register("filename", { maxLength: 20, pattern: ONLY_LETTERS_NUMBERS_DASH_AND_UNDERSCORE })} />
                    <span className="my-1" role="alert">
                        {errors.filename?.type === "pattern" && `Sólo se permiten letras, números, guiones y guiones bajos.`}
                    </span>

                    <div className='mt-3 d-flex justify-content-end'>
                        <ModalButton className="cancel" onClick={closeHandler}>
                            Cancelar
                        </ModalButton>
                        <ModalButton className="action" type='submit'>
                            Exportar
                        </ModalButton>
                    </div>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 13px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 13px;
        min-height: 12px;

        &.note {
            color: #000;
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
`;

const ModalButton = styled(Button)`
    border: none;

    &.cancel {
        background-color: #A5A7A0;
        margin-right: 15px;
    }
    
    &.action {
        background-color: #D04437;
    }
`;

export default ExportAddFileNameModal;
