import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import { ONLY_NUMBERS_GREATER_THAN_ZERO, TWO_DECIMALS_GREATER_THAN_ZERO } from '../../constants/regex';

const IssueEditDetailModal = ({ show, detail, closeHandler, actionHandler }) => {

    // ----- State
    let [regexAmount, setRegexAmount] = useState(null);
    let [showAmountError, setShowAmountError] = useState(false);

    // ----- Context/Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            amount: '',
        }
    });

    useEffect(() => {
        reset(detail)

        if (detail.measureUnit === "UNITS") {
            setRegexAmount(ONLY_NUMBERS_GREATER_THAN_ZERO)
        }
        else {
            setRegexAmount(TWO_DECIMALS_GREATER_THAN_ZERO);
        }
    }, [detail, reset]);


    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = (data) => {
        if (detail.currentAmount < Number(data.amount)) {
            setShowAmountError(true);
        } else {
            detail.amount = Number(data.amount);
            actionHandler(detail);
        }
    }

    const handleAmountChange = (e) => {
        setShowAmountError(false);
        if (detail.currentAmount < Number(e.target.value)) {
            setShowAmountError(true);
        }
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Editar detalle
            </Modal.Header>
            <Modal.Body>
                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <p className='fw-bold mb-0 text-center'>{detail.articleName} ({detail.brand})</p>
                    <span className='text-center info'>Cantidad actual: {detail.currentAmount}</span>
                    <hr />

                    <label className="d-flex mb-1" htmlFor="amount">Cantidad</label>
                    <input className="form-control" type="text" id="amount" autoComplete="off" maxLength={10} {...register("amount", { required: true, maxLength: 10, pattern: regexAmount, onChange: handleAmountChange })} />
                    <span className="my-1" role="alert">
                        {errors.amount?.type === "required" && `Campo requerido`}
                        {errors.amount?.type === "maxLength" && `Formato inválido`}
                        {errors.amount?.type === "pattern" && detail.measureUnit !== "UNITS" && `Sólo se permiten números (5 - 3.50)`}
                        {errors.amount?.type === "pattern" && detail.measureUnit === "UNITS" && `Sólo se permiten números enteros (5 - 100)`}
                        {showAmountError && `Debe ser menor o igual a la cantidad actual`}
                    </span>

                    <div className='mt-3 d-flex justify-content-end'>
                        <ModalButton className="cancel" onClick={handleClose}>
                            Cancelar
                        </ModalButton>
                        <ModalButton className="action" type="submit">
                            Editar
                        </ModalButton>
                    </div>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 13px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 12px;
        min-height: 12px;

        &.info {
            color: green;
            font-size: 14px;
            font-weight: 500;
        }
    }

    > button {
        background-color: #D04437;
    }
`;

const ModalButton = styled(Button)`
    border: none;

    &.cancel {
        background-color: #A5A7A0;
        margin-right: 15px;
    }
    
    &.action {
        background-color: #D04437;
    }
`;

export default IssueEditDetailModal;
